<template>
  <div :key="$route.params.form_id">

    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col>
          <v-btn color="primary" depressed class="mb-4 white--text" :to="{name: 'form_registrations'}">
            <v-icon left dark>mdi-arrow-left</v-icon> Registraties
          </v-btn>
        </v-col>
        <v-col class="d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-sm-end justify-md-end justify-lg-end justify-xl-end">
          <v-btn color="secondary"
                 outlined
                 depressed
                 class="mb-4 mr-4"
                 @click="resendRegistration"
                 :disabled="resendRegistrationLoading"
                 :loading="resendRegistrationLoading"
                 v-if="registration && registration.registration_status === 'completed'"
          >
            <v-icon
                left
                dark
            >
              mdi-sync
            </v-icon>
            Opnieuw versturen
          </v-btn>

          <v-tooltip bottom v-if="!registrationIsDeleted() && userHasPermission('eform manage form registrations')">
            <template v-slot:activator="{ on }">
              <v-btn
                  color="secondary"
                  outlined
                  depressed
                  class="mr-4"
                  :disabled="downloadEmailsLoading"
                  :loading="downloadEmailsLoading"
                  @click="downloadEmailAttachments"
                  v-on="on"
              >
                <v-icon
                    left
                    dark
                >
                  mdi-download
                </v-icon>
                Download email bijlagen
              </v-btn>
            </template>
            <span>Bijlagen worden gedownload met de nieuwste versie van de email instellingen</span>
          </v-tooltip>

          <v-btn
              v-if="!registrationIsDeleted() && userHasPermission('eform manage form registrations delete')"
              color="error"
              depressed
              class="mb-4"
              @click="trashRegistrationConfirm"
          >Registratie verwijderen</v-btn>

          <v-btn
              v-if="registrationIsDeleted() && userHasPermission('eform manage form registrations delete')"
              color="warning"
              depressed
              class="mb-4"
              @click="untrashRegistrationConfirm"
          >Registratie uit prullenbak halen</v-btn>

        </v-col>
      </v-row>
    </v-container>

    <template v-if="registration">

      <v-sheet class="pa-4 rounded-lg">
        <v-skeleton-loader type="sentences, text@3, sentences, text@2" v-if="loading"></v-skeleton-loader>

        <v-container v-if="!loading">
          <v-row>
            <v-col>
              <h4>Registratiedatum</h4>
              <div class="value">{{ registration.registration_date|formatDate }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4>Serienummer</h4>
              <div class="value">{{ registration.registration_serial_number }}</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4>Gebruiker</h4>
              <div class="value">{{ registration.registration_user_id.username }}</div>
            </v-col>
          </v-row>
          <v-row v-if="registration.form_type === 'workflow'">
            <v-col>
              <h4>Fase</h4>
              <div class="value">{{ registration.registration_phase }}</div>
            </v-col>
          </v-row>
          <eform-registration-formatter :schema="schema" :registration-values="registration.values"></eform-registration-formatter>
        </v-container>
      </v-sheet>

    </template>

  </div>
</template>


<script>
import axios from "axios";
import EformRegistrationFormatter from "@/components/EformRegistrationFormatter";
import userHelpers from "../../mixins/userHelpers";
import _ from "lodash";

export default {
  name: "FormRegistrationView",
  components: {
    EformRegistrationFormatter
  },
  mixins: [
      userHelpers
  ],
  data() {
    return {
      loading: false,
      resendRegistrationLoading: false,
      downloadEmailsLoading: false,
      registration: null,
      form_id: this.$route.params.form_id,
      registration_id: this.$route.params.registration_id,
    }
  },
  computed: {
    schema() {
      return this.form.schema
    },
  },
  mounted () {
    // Get form data. This fetches the form first and then the registration data.
    this.getDataFromApi()
  },
  methods: {
    async getDataFromApi() {
      this.loading = true
      await this.getFormDataFromApi()
      await this.getRegistrationDataFromApi()
      this.loading = false
    },
    getFormDataFromApi () {
      // Fetch results form the API
      return axios
          .get('api/form/'+this.form_id)
          .then(response => {
            this.form = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    getRegistrationDataFromApi () {
      // Fetch results form the API
      return axios
          .get('api/form/'+this.form_id+'/registrations/'+this.registration_id)
          .then(response => {
            this.registration = response.data
          })
          .catch(error => {
            console.log(error)
          })
    },
    async trashRegistrationConfirm() {
      if (
          confirm("Weet je zeker dat je deze registratie wilt verwijderen?")
      ) {
        this.trashRegistration();
      }
    },
    async untrashRegistrationConfirm() {
      if (
          confirm("Weet je zeker dat je deze registratie uit de prullenbak wilt halen?")
      ) {
        this.untrashRegistration();
      }
    },
    async trashRegistration() {
      this.loading = true
      // Fetch results form the API
      axios
        .get('api/form/'+this.form_id+'/registrations/'+this.registration_id+'/trash')
        .then(response => {
          this.$toast.success("Registratie is verplaatst naar de prullenbak.");
          this.registration = response.data
          this.$router.push({name: 'form_registrations', 'form_id': this.form_id});
        })
        .catch(error => {
          console.log(error)
          this.$toast.error("Registratie kon niet worden verplaatst naar de prullenbak.");
        })
        .finally(() => this.loading = false)

    },
    async untrashRegistration() {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations/'+this.registration_id+'/untrash')
          .then(response => {
            this.$toast.success("Registratie is uit de prullenbak gehaald.");
            this.registration = response.data
            this.$router.push({name: 'form_registrations', 'form_id': this.form_id});
          })
          .catch(error => {
            console.log(error)
            this.$toast.error("Registratie kon niet uit de prullenbak gehaald worden.");
          })
          .finally(() => this.loading = false)

    },
    resendRegistration() {
      this.resendRegistrationLoading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations/'+this.registration_id+'/resend')
          .then(response => {
            if (response && response.data && response.data.status === 'success') {
              this.$toast.success("Registratie is opnieuw verstuurd.");
            }
            else {
              this.$toast.error("Registratie kon niet opnieuw verstuurd worden.");
            }
          })
          .finally(() => this.resendRegistrationLoading = false)
    },
    downloadEmailAttachments() {
      this.downloadEmailsLoading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/registrations/'+this.registration_id+'/download-emails')
          .then(response => {
            if (response && response.data && response.data.status === 'success') {
              // In response.data.url is the download link. Start a download.
              window.open(response.data.url, '_blank');

              this.$toast.success("Bestand download is gestart.");
            }
            else {
              this.$toast.error("Bestand konden niet worden gedownload.");
            }
          })
          .finally(() => this.downloadEmailsLoading = false)
    },
    registrationIsDeleted() {
      return _.has(this.registration, 'deleted_at');
    },
  }
}
</script>

<style lang="scss" scoped>

</style>